@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
body {
    background-color: #FFF;
    color: black;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    font-family: 'Roboto', sans-serif;
}
@media (max-width: 768px) {
    body {
        width: 100%;
    }
}




.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  position: relative;
}
.modal.show {
  display: flex;
}
.modal img {
  max-width: 100%;
  max-height: 100%;
}
.close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 35px;
  color: black;
  background: #CCFE06;
  border-radius: 10%;
}
.close:hover {
  color: #A0C60A;
}




.free-shipping-banner {
    display: flex;
    justify-content: center;
    height: 38px;
    margin: 0 auto;
    line-height: 30px;
    font-family: "Arial", sans-serif;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #000;
    color: #FAFAFA;
    z-index: 9999;
    border-bottom: 4px solid #CCFE06;
}
.free-shipping-text {
    margin-right: 100px;
    margin-top: 4px;
}
@media (max-width: 768px) {
    .free-shipping-banner {
        height: 38px;
    }
    .free-shipping-text {
        margin-right: 20px;
        margin-top: 4px;
        font-size: 12px;
        text-align: center;
    }
}




.carrito {
  display: flex;
  justify-content: center;
  background-color: #CCFE06;
  padding: 7px;
  margin-top: 3px;
  color: black;
  align-items: center;
  height: 15px;
}
.carrito a {
  text-decoration: none;
}
.carrito-icon {
  width: 24px;
  height: 24px;
  transform: translateY(5px);
}
.carrito-total {
  color: #000000;
  font-weight: bold;
  line-height: 100px;
}




/*.nav-container {
    width: 100%;
}*/
.logo img {
    height: 70px;
    margin-right: 50px;
    margin-left: 150px;
}
.nav-links {
    list-style: none;
    display: flex;
    padding-left: 10; 
}
.nav-links li {
    padding: 0 40px;
}
.nav-links a {
    display: inline-block;
    position: relative;
    color: #FFFFFF;
    text-decoration: none;
    font-size: 19px;
}
.nav-links a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 5px;
    bottom: -7px;
    left: 0;
    background-color: #CCFE06;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.nav-links a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
.hamburger {
  display: none;
  cursor: pointer;
}
.hamburger div {
  width: 50px;
  height: 3px;
  background-color: #d2cbc6ff;
  margin: 5px 0;
  transition: all 0.3s;
}
@media (max-width: 768px) {
    .nav-container {
    	width: 80%;
    }
    .nav-links {
        position: absolute;
        right: 0;
        left: 0;
        top: 58px;
        background-color: #333;
        width: 50%;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        max-height: 0;
        display: block;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5); /* Quitar (?) */
        margin: 0 auto;
        border-radius: 10px;
    }
    .nav-links.active {
        max-height: 1000px; /* Un valor grande para asegurarse de que quepa todo el contenido */
    }
    .nav-links li {
        padding: 15px 0;
    }
    .hamburger {
        display: block;
    }
    .hamburger.active + .nav-links {
        display: block;
    }
    .logo img {
        //margin-right: 170px;
        //margin-left: 135px;
    }
}




.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.hero-content {
  color: #fff;
  text-align: right;
  padding-right: 20px;
  padding-right: 20px;
  padding-right: 100px;
}
.hero-content h1 {
  font-size: 3rem;
}
.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}
.hero-content button {
  padding: 10px 20px;
  background-color: #CCFE06;
  border: none;
  color: #000;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.hero-content button:hover {
  background-color: #a0dd00;
}
@media only screen and (max-width: 767px) {
  .hero-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .hero-content {
    text-align: center;
    padding: 0 20px;
  }
}




.info-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.info-banner-main-container {
  width: 100vw;
  background-color: #CCFE06;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-banner-main-text {
  font-size: 2rem;
  font-weight: bold;
  color: black;
}
.info-banner-items {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 20px;
}
.info-banner-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
}
.info-banner-icon {
  width: 150px;
  height: 150px;
}
.info-banner-item-text {
  font-size: 1.0rem;
  color: white;
}
.info-banner-separator {
  width: 100vw;
  height: 30px;
  padding-bottom: 10px;
  background-color: #CCFE06;
}
@media (max-width: 768px) {
  .info-banner-items {
    flex-wrap: wrap;
    gap: 50px;
  }
  .info-banner-item {
    width: 25%;
    }
  .info-banner-icon {
    width: 100px;
    height: 100px;
    }
  .info-banner-item-text {
    display: none;
  }
  .info-banner-main-container {
    padding: 30px 0;
  }
  .info-banner-main-text {
    text-align: center;
    font-size: 1.8rem;
  }
}




.product-section {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}
.product-header {
  position: relative;
}
.header-image {
  position: absolute;
  top: 0;
  right: 1000px;
  width: 100px;
  height: auto;
}
.product-header h2 {
  margin: 0;
  font-size: 45px;
  color: #333;
}
.product-header hr {
  border: 0;
  border-top: 4px solid #333;
  margin: 10px auto 40px;
  width: 100%;
  max-width: 80px;
  text-align: center;
}
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.product-grid-item {
  width: 93%;
}
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin-bottom: 25px;
  background: #eceff1;
}
.product-card .product-details p span {
  font-weight: normal;
}
.product-card button{
  background-color: #000000;
  border: none;
  color: #ccfe06;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.product-card button:hover{
  background-color: #505050;
  transform: scale(1.05);
}
.image-container {
  position: relative;
  margin-bottom: 80px;
}
.price-banner {
  position: absolute;
  bottom: -45px;
  background-color: black;
  padding: 10px;
  width: 300px;
  height: 50px;
  font-weight: bold;
  color: #ccfe06;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.size-banner {
  padding: 10px;
  border-radius: 5px;
  width: 280px;
  font-weight: bold;
}
.color-container {
  display: flex;
  align-items: flex-start;
  margin-right: 15px;
  font-weight: bold;
  margin-bottom: 40px;
}
.product-details {
  margin-top: -40px;
  margin-bottom: 25px;
}
.product-details h2 {
  margin-bottom: 10px;
}
.product-details p {
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .product-grid-item {
    width: 100%;
  }
  .product-header h2 {
    font-size: 25px;
    width: 100px;
    text-align: center;
    margin: 0 auto;
  }
  .header-image {
    right: 200px;
    width: 100px;
  }
}




.success-bubble {
  position: absolute;
  padding: 10px;
  background-color: rgba(205, 254, 6, 0.7);
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  animation: moveUp 3s forwards;
}
@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}
.success-bubble.show {
  opacity: 1;
}




.alert-bubble {
  position: absolute;
  /*background-color: rgba(255, 0, 0, 0.8);*/
  background-color: rgba(255, 0, 0);
  color: white;
  padding: 10px;
  border-radius: 5px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  font-weight: bold;
}
.alert-bubble.show {
  opacity: 1;
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1); /* Tamaño original */
  }
  50% {
    transform: scale(1.1); /* Aumentar el tamaño */
  }
  100% {
    transform: scale(1); /* Volver al tamaño original */
  }
}
.alert-bubble.hide {
  opacity: 0;
}




.detail-page{
  margin-top: 120px;
}
.product-detail-container {
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 100px;
  background-color: #f9f9f9;
}
.product-detail-columns {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.product-images {
  flex: 1;
  margin-top: 85px;
}
.main-image-container {
  max-width: 50%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  overflow: hidden;
}
.main-image {
  margin: 0 auto;
  transition: transform 0.2s ease;
  transform-origin: center center;
}
.main-image-container:hover  {
  transform: scale(1.2);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}
.thumbnail-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 9px;
}
.thumbnail-container img {
  width: 50px;
  height: auto;
  border: 1px solid #ccfe06;
  cursor: pointer;
  transition: border-color 0.3s;
}
.product-info{
  flex: 1;
  margin-top: 50px;
}
.product-info button {
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.product-info button:hover {
  transform: scale(1.05);
}
.product-info button:last-of-type {
  background-color: #CCFE06;
  color: black;
  margin-left: 10px;
  width: 190px;
}
.product-info button:last-of-type:hover {
  background-color: #A6CB05;
}
.product-info button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}
.price-banner-detail {
  background-color: black;
  width: 300px;
  height: 50px;
  margin-top: 20px;
  font-weight: bold;
  color: #ccfe06;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.product-detail-container h2 {
  margin-top: 30px;
  font-size: 20px;
}
.product-detail-container button{
  background-color: #000000;
  border: none;
  color: #ccfe06;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}
.additional-info-container {
  max-width: 70%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1em;
  line-height: 1.5;
  color: #333;
}
.additional-info-container h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}
.additional-info-container p {
  margin: 0;
}
.related-products-container {
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.related-product {
  flex: 0 1 calc(33.333% - 20px);
  margin: 0 10px;
  text-align: center;
}
.related-products {
  margin-top: 50px;
  text-align: center;
}
.related-products h2{
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
  background-color: #ccfe06;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}
.related-product-image {
  max-width: 70%;
  height: auto;
}
.related-product-info {
  margin-top: 10px;
}
/* #resenas-detalle {
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  width: 80%;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
} */
.resenas-detalle-section{
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}
.resenas-carousel {
  display: flex;
  transition: transform 0.5s ease;
}
.resena-detalle {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-left: 20px;
}
@media (max-width: 768px) {
  .product-detail-columns {
    flex-direction: column;
    align-items: center;
  }
  .product-images {
    margin-top: 65px;
    margin-bottom: 20px;
  }
  .main-image-container {
    max-width: 100%;
  }
  .product-info {
    margin-top: 0;
    text-align: center;
  }
  .product-info button {
    margin: 0 auto;
    width: 70%;
    margin-bottom: 15px;
  }
  .product-info button:last-of-type {
    width: 70%;
    margin-left: 0px;
  }
  .size-banner{
    margin: 0 auto;
  }
  .color-container{
    justify-content: center;
  }
  .additional-info-container{
    max-width: 90%;
  }
  .related-products-container {
    max-width: 100%;
  }
  .related-product {
    flex: 1 0 30%;
    margin: 0 10px;
    text-align: center;
  }
  .related-product-image {
    max-width: 100%;
    height: auto;
  }
  .related-product {
    flex: 1 0 48%;
    margin: 10px 1%;
  }
  .resenas-detalle-section{
    width: 100%;
  }
  .resena-detalle {
    margin-left: 0px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none; 
  }
}




.image-wrapper {
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 50px;
}
.desktop-image {
  width: 100%;
  height: auto;
  display: block;
}
.mobile-image {
  width: 100%;
  height: auto;
  display: none;
}
@media (max-width: 768px) {
  .desktop-image {
    display: none;
  } 
  .mobile-image {
    display: block;
  }
}




.resenas-section {
  background-color: #f9f9f9;
  padding: 50px 20px;
  text-align: center;
}
.resenas-section h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
  background-color: #ccfe06;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}
.resenas-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1000px;
  justify-content: center;
  margin: 0 auto;
}
.resena {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  transition: transform 0.3s ease;
}
.resena:hover {
  transform: scale(1.05);
}
.resena h4 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}
.resena p {
  margin: 5px 0;
  font-size: 1rem;
  color: #555;
}
.resena p:first-of-type {
  color: #ffcc00;
  font-size: 1.2rem;
}
.resena p:last-of-type {
  font-size: 1rem;
  font-style: italic;
  color: #777;
}
.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.resena-social-icon {
  margin-left: 155px;
}
.resena-social-icon-img {
  width: 40px;
  height: 40px;
}
@media (max-width: 768px) {
  .resenas-container {
    grid-template-columns: 1fr;
    gap: 15px;
  }
  .resena {
    width: 80%;
    margin: 0 auto;
  }
}




.footer {
  display: flex;
  justify-content: space-between;
  padding: 100px;
  background-color: #000;
  color: #fff;
  margin-top: 70px;
}
.footer-column {
  flex: 1;
  margin: 0 20px;
}
.footer-logo {
  width: 70px;
  margin-bottom: 10px;
}
.whatsapp-link {
  text-decoration: none;
  color: #fff;
}
.whatsapp-icon {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  vertical-align: middle;
}
.social-icons {
  display: flex;
  gap: 10px;
}
.social-icon {
  width: 30px;
  height: 30px;
}
.footer-column h3 {
  margin-bottom: 10px;
}
.footer-column p {
  margin: 5px 0;
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin: 10px 0;
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }
}




.carrito-page {
  display: flex;
  margin-top: 65px;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f9f9;
}
@media (max-width: 768px) {
  .carrito-page {
    max-width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }
}


.cart-container {
  margin-top: 100px;
  flex: 1;
  margin-right: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.titulo-resumen {
    margin-left: 35px;
    margin-bottom: 35px;
}
.cart-item {
  width: 45%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.cart-container ul {
  list-style: none;
  padding: 0;
}
.cart-container li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.cart-container li:last-child {
  border-bottom: none;
}
.cart-item{
  width: 500px;
}
.item-info {
  flex: 1;
  margin-left: 15px;
}
.product-name {
  font-size: 1.5em;
  font-weight: bold;
}
.item-info p {
  margin: 5px 0;
  color: #333;
}
.cart-item img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.cart-item {
  display: flex;
  align-items: center;
}
.quantity-widget {
  display: flex;
  align-items: center;
}
.cart-item .quantity-widget button {
  background-color: black;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  color: #ccfe06;
  transition: background-color 0.3s;
  width: 35px;
  font-weight: bold;
}
.cart-item .quantity-widget button:hover {
  background-color: #605B5B;
}
.cart-item .quantity-widget span {
  margin: 0 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}
.cart-item .remove-button {
  background-color: #000000;
  border: none;
  color: #ccfe06;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: bold;
}
.cart-item .remove-button:hover {
  background-color: #605B5B;
}
.cart-item .color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 0;
}
@media (max-width: 768px) {
  .cart-container {
    width: 100%;
    margin-top: 100px;
    margin-right: 0;
    order: 2;
  }
  .titulo-resumen {
    text-align: center;
    margin-left: 0px;
  }
  .cart-item {
    width: 90%;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .item-info {
    margin-left: 0px;
    margin-right: 5px;
  }
  .cart-item img {
    margin: 0 auto 10px;
    width: 180px;
    height: 180px;
  }
  .cart-item .quantity-widget,
  .cart-item .remove-button {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  .cart-item .quantity-widget {
    justify-content: center;
  }
  .cart-item .remove-button {
    text-align: center;
  }
}


.resumen-container {
  position: sticky;
  margin-top: 100px;
  top: 0;
  max-height: 1000px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
}
.resumen-pedido-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.resumen-pedido-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}
.resumen-pedido-table th, .resumen-pedido-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.resumen-pedido-table th {
  background-color: black;
  color: #ccfe06;
}
.resumen-pedido-summary {
  text-align: right;
  margin: 20px 0;
}
.whatsapp-assistance {
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: flex-end;
}
.whatsapp-link-resumen {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.whatsapp-icon-resumen  {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.resumen-pedido-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}
.button-container {
  text-align: right;
  margin-top: 10px;
}
.resumen-pedido-button {
  background-color: #000000;
  border: none;
  color: #ccfe06;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: bold;
}
.resumen-pedido-button:hover {
  background-color: #605B5B;
}
.resumen-pedido-image {
  display: block;
  margin-top: 20px;
}
.resumen-pedido-summary p {
  margin: 5px 0; /* Espaciado entre las líneas */
}
@media (max-width: 768px) {
  .resumen-container {
    width: 85%;
    margin-top: 20px;
    order: 1;
  }
  .resumen-pedido-image {
    max-width: 100%;
  }
  .resumen-pedido-table {
    display: none;
  }
  .resumen-pedido-summary {
    text-align: center;
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}




.finalizar-compra-page {
  display: flex;
  justify-content: space-between;
}
.finalizar-compra-container {
  flex: 1;
  max-width: 600px;
  margin: 200px 0 0 100px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}
form {
  display: flex;
  flex-direction: column;
}
.error-message {
    color: red;
    font-size: 14px;
    margin-top: -10px;
}
.formulario {
  margin-bottom: 20px;
}
.formulario h3 {
  margin-bottom: 10px;
}
.formulario label {
  display: block;
  margin-bottom: 5px;
}
.formulario input {
  width: 95%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  padding: 10px 20px;
  background-color: black;
  color: #ccfe06;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15.5px;
}
button:hover {
  background-color: #605B5B;
}
.resumen-con-imagen-container {
  position: sticky;
  margin-top: 200px;
  margin-right: 65px;
  top: 0;
  max-height: 500px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
}
.productos-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.productos-container p {
  margin: 1px 0;
}
.productos-container p:last-child {
  margin-bottom: 25px;
}
.label {
  font-weight: bold;
}
.producto {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.producto-imagen {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
@media only screen and (max-width: 768px) {
  .finalizar-compra-page {
    flex-direction: column;
  }
  .resumen-con-imagen-container {
    position: static;
    width: 86%;
    margin: 0 auto;
    margin-top: 180px;
    box-shadow: none;
    order: 1;
  }
  .finalizar-compra-container {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
  }
  button {
    margin-top: 15px;
    margin-left: 50px;
  }
}




/* Spinner.css */
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.loading {
    border: 8px solid #ccfe06;
    border-top: 8px solid #94b804;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}




.selectable {
  cursor: pointer;
}
.selected {
  border: 2px solid #000;
}



